<template>
  <div class="page">
    <div class="top_box">
      <img
        style="width: 100%; height: 100%"
        src="./img/teacherBanner.png"
        alt=""
      />
    </div>
    <div class="detail_box">
      <div class="tab">
        <div class="color_block"></div>
        <div class="infos">{{ detail_data.name }}</div>
        <div class="imgs">
          <div v-if="detail_data.sex == 2">
            <img class="icon" src="./img/female_icon.png" alt="" />
          </div>
          <div v-else>
            <img class="icon" src="./img/male_icon.png" alt="" />
          </div>
        </div>
      </div>
      <v-h5Mtml :content="detail_data.introduction"></v-h5Mtml>
    </div>
  </div>
</template>

<script>
import { getCpbTeacherInfoByIdUrl } from "./api.js";
export default {
  name: "teacherDetail",
  data() {
    return {
      id: "",
      detail_data: {},
    };
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      let params = {
        id: this.id,
      };
      this.$axios
        .get(`${getCpbTeacherInfoByIdUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.detail_data = res.data;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  height: 100vh;
  background: #ffffff;

  .detail_box {
    margin-top: -12px;
    width: 750px;
    height: calc(100vh - 320px);
    background: #ffffff;
    border-radius: 16px;

    .content {
      width: 100%;
      box-sizing: border-box;
      padding: 0 57px;
      font-size: 32px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
    }

    .tab {
      width: 100%;
      height: 146px;
      padding-left: 24px;
      padding-top: 40px;
      box-sizing: border-box;

      .color_block {
        display: inline-block;
        width: 8px;
        height: 68px;
        background: #007eff;
        border-radius: 4px;
        vertical-align: bottom;
      }

      .imgs {
        display: inline-block;
        vertical-align: bottom;

        .icon {
          width: 44px;
          height: 44px;
        }
      }

      .infos {
        margin-left: 26px;
        margin-right: 10px;
        vertical-align: bottom;
        display: inline-block;
        font-size: 64px;
        font-weight: 600;
        color: #000;
      }
    }
  }

  .top_box {
    width: 750px;
    height: 320px;
    background-color: #5fa4fc;
  }
}
</style>
